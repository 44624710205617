require("fslightbox");

const galerySwiper = () => {
  const blocks = document.querySelectorAll(".block-galerie-image .swiper");

  if (blocks.length === 0) {
    return;
  }
  [...blocks].forEach((item) => {
    const delayvalue = item.dataset.autoplay;
    const { slideperview, slideperviewmobile } = item.dataset;
    const breakpoints = { 320: {}, 1024: {} };
    let autoplay = false;
    if (typeof delayvalue !== "undefined") {
      autoplay = {
        delay: delayvalue * 1000,
      };
    }
    if (typeof slideperviewmobile !== "undefined") {
      breakpoints["320"].slidesPerView = Number(slideperviewmobile);
    }
    if (typeof slideperview !== "undefined") {
      breakpoints["1024"].slidesPerView = Number(slideperview);
    }

    const config = {
      loop: true,
      autoplay,
      lazy: true,
      breakpoints,
      spaceBetween: 38,
      centeredSlides: true,
      pagination: {
        el: ".swiper-pagination",
        type: "fraction",
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    };

    const swiper = new Swiper(item, config);// eslint-disable-line

    // handle lightbox if it's a image slider
    if (item.dataset.choice === "image") {
      refreshFsLightbox();// eslint-disable-line
    }
  });
};

document.addEventListener("DOMContentLoaded", () => {
  galerySwiper();
});
